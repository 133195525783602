import React, { useContext, useEffect, useState } from "react";
// import { authApi } from "../pages/_app";
// import { motion } from "framer-motion";
// import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  borderRadius: 1,
  boxShadow: 24,
  py: 1,
  px: 4,
};

function FormI() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState();
  const [profession, setProfession] = useState();
  const [topic, setTopic] = useState();
  const [select, setSelect] = useState();
  const [details, setDetails] = useState();
  const nextHandler = (e) => {};
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="text-center sm:w-[450px] w-[300px] md:w-[450px] w-[300px] bg-[#fff] grid grid-cols-1 gap-4 border-2 border-[#F55050]"
        >
          <div className="text-bold text-[#F55050] text-2xl">
            Please fill all the fields!!
          </div>
          <div onClick={() => setOpen(false)} className="w-100 text-center">
            {" "}
            <button className="text-white py-1 border-2 border-[#F55050] w-[100px] bg-[#F48484] rounded-md">
              OK
            </button>
          </div>
        </Box>
      </Modal>
      <form action="#" className="mt-8 grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label className="block text-3xl font-large text-gray-800">
            Name
          </label>

          <input
            value={name}
            type="text"
            name="name"
            placeholder="Enter Name"
            className="p-2 h-10 mt-1 w-full rounded-md border-[1px] border-[#9a2be9] bg-white text-xl text-gray-700 shadow-sm"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label className="block text-3xl font-large text-gray-800">
            Profession
          </label>

          <input
            value={profession}
            type="text"
            name="profession"
            placeholder="Enter Profession"
            className="p-2 h-10 mt-1 w-full rounded-md border-[1px] border-[#9a2be9] bg-white text-xl text-gray-700 shadow-sm"
            onChange={(e) => setProfession(e.target.value)}
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label className="block text-3xl font-large text-gray-800">
            Topic
          </label>

          <input
            value={topic}
            type="text"
            name="topic"
            placeholder="Enter Topic"
            className="p-2 h-10 mt-1 w-full rounded-md border-[1px] border-[#9a2be9] bg-white text-xl text-gray-700 shadow-sm"
            onChange={(e) => setTopic(e.target.value)}
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label className="block text-3xl font-large text-gray-800">
            Why should we select you?
          </label>

          <textarea
            value={select}
            name="select"
            rows="2"
            placeholder="Relevence to theme and how it is supposed to benefit IITI community"
            className="p-2 mt-1 w-full rounded-md border-[1px] border-[#9a2be9] bg-white text-xl text-gray-700 shadow-sm overflow-hidden"
            onChange={(e) => setSelect(e.target.value)}
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label className="block text-3xl font-large text-gray-800">
            Any other details?
          </label>

          <textarea
            value={details}
            name="details"
            rows="2"
            placeholder="Details"
            className="p-2 mt-1 w-full rounded-md border-[1px] border-[#9a2be9] bg-white text-xl text-gray-700 shadow-sm overflow-hidden"
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>
        <div className="col-span-6 sm:flex sm:items-center sm:gap-4 w-100 flex justify-center items-center">
          <button
            onClick={nextHandler}
            className="inline-block shrink-0 rounded-md border border-[#9a2be9] bg-[#9a2be9] px-12 py-3 text-3xl font-medium text-white transition hover:bg-transparent hover:text-[#9a2be9] focus:outline-none focus:ring active:text-blue-500"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default FormI;
