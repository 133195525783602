
import React from "react";
import SpeakersCard from "../../components/SpeakersCard/SpeakersCard";
import { useState, useEffect } from "react";
import Data from "../../data/Data";
import Button from "../../components/Button/Button";
import FormI from "../../components/Form/FormI";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import './SpeakersPage.css'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  // width: "400px",
  pt: 1,
  pb: 4,
  px: 3,
};
export default function SpeakersPage() {
  const [speakers, setSpeakers] = useState([]);
  const [key, setKey] = useState(2023);

  useEffect(() => {
    setSpeakers(Data().speakers.filter((x) => x.date.includes(key)));
  }, [key]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const Sonnet = () => (
    // key === 2022 ? <div className='h3 text-center my-5'> <br /> <br /> Stay Tuned ! <br /><br /></div> :
    <div className="row p-0">
      {speakers.map((speaker) => (
        <div
          data-aos={"fade-up"}
          data-aos-duration="1000"
          className=" col-12 col-md-6 col-lg-3 py-5 d-flex justify-content-center"
        >
          {" "}
          <SpeakersCard speaker={speaker} key={speaker.sno} />
        </div>
      ))}
    </div>
  );
  
  return (
    <>
      {/* <div className="flex justify-center mt-2">
        <button
          onClick={() => {
            setOpen(true);
          }}
        >
          {<Button text={"Call For Speakers"}></Button>}
        </button>
      </div> */}
      <section
        className="talks py-5 text-light ">
        <div className="h1 text-center">Speakers ({key})</div>
        <div className="container">
          <div
            className="d-flex mb-4"
            style={{
              borderBottom: "1px solid white",
            }}
          >
            <div
              className="p-2"
              style={{
                borderRight: "1px solid white",
              }}
            >
              <div
                role={"button"}
                onClick={() => setKey(2023)}
                className={`${
                  key === 2023 ? "buttonBg text-light" : "bg-transparent"
                } px-3 py-1 rounded-pill`}
              >
                2023
              </div>
            </div>
            <div
              className="p-2"
              style={{
                borderRight: "1px solid white",
              }}
            >
              <div
                role={"button"}
                onClick={() => setKey(2022)}
                className={`${
                  key === 2022 ? "buttonBg text-light" : "bg-transparent"
                } px-3 py-1 rounded-pill`}
              >
                2022
              </div>
            </div>
            <div
              className="p-2"
              style={{
                borderRight: "1px solid white",
              }}
            >
              <div
                role={"button"}
                onClick={() => setKey(2018)}
                className={`${
                  key === 2018 ? "buttonBg text-light" : "bg-transparent"
                } px-3 py-1 rounded-pill`}
              >
                2018
              </div>
            </div>
            <div className="p-2">
              <div
                role={"button"}
                onClick={() => setKey(2017)}
                className={`${
                  key === 2017 ? "buttonBg text-light" : "bg-transparent"
                } px-3 py-1 rounded-pill`}
              >
                2017
              </div>
            </div>
          </div>
          <Sonnet />
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="sm:w-[300px] md:w-[450px] w-[90%] grid grid-cols-1 gap-4"
        >
          <FormI></FormI>
        </Box>
      </Modal>
    </>
  );
}
