import React, { useEffect } from "react";
import "./SponsorsPage.css";
import CircularCard from "../../components/CircularCard/CircularCard";
import BrandCard from "../../components/BrandCard/BrandCard";
import SponsorCard from "../../components/SponsorCard/SponsorCard";
import SponsorCard2 from "../../components/SponsorCard/SponsorCard2";

export default function SponsorsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const cur_sponsors = [
    "/images/logos/latest_sponsers/Cubastion.jpg",
    "/images/logos/latest_sponsers/edugene.png",
    "/images/logos/latest_sponsers/bbq2.png",
    "/images/logos/latest_sponsers/kapda.png",
    "/images/logos/latest_sponsers/gift.png"
  ];
  const sponsors = [
    "/images/sponsors/canara.png",
    "/images/sponsors/yash.png",
    "/images/sponsors/ims.jpg",
    "/images/sponsors/edscope.webp",
  ];
  return (
    <>
      <section class="hero-section">
        <div class="main">
          <div className="title">
            <h1>Call for <span className="title-sub">Sponsors</span></h1>
          </div>
          <div className="sub-title">
            <h2>Why TEDx IIT Indore</h2>
          </div>
          <ul class="cards">
            <div className="cards-cards">
              <li class="cards_item">
                <CircularCard
                  props={"Brand IIT Indore"}
                  children={"Providing closer location to business hubs."}
                  icon={"fas fa-copyright"}
                ></CircularCard>
              </li>
              <li class="cards_item">
                <CircularCard
                  props={"Establish Brand Identity"}
                  children={
                    "Association with one of the best college in Central India."
                  }
                  icon={"fas fa-thumbs-up"}
                />
              </li>
              <li class="cards_item">
                <CircularCard
                  props={"Optimum ROI"}
                  children={"Visibility across myriads sectors across nation."}
                  icon={"fas fa-dollar-sign"}
                />
              </li>
              <li class="cards_item">
                <CircularCard
                  props={"Global Brand Promotion"}
                  children={
                    "As a premier Indian Institution, IITI has a worldwide reach."
                  }
                  icon={"fas fa-globe"}
                />
              </li>
              <li class="cards_item">
                <CircularCard
                  props={"Diverse Marketing Survey"}
                  children={
                    "Consumer sampling and market preferences at one place."
                  }
                  icon={"fas fa-newspaper"}
                />
              </li>
            </div>
          </ul>
        </div>
      </section>

      <section class="branding-section">
        <div class="main">
          <div className="sub-title">
            <h2 className="branding">BRANDING</h2>
            <ul class="cards">
              <div className="brand-column">
                <div className="brand-row">
                  <li class="brand-card">
                    <BrandCard content={"On Screen"}></BrandCard>
                  </li>
                  <li class="brand-card">
                    <BrandCard content={"In Campus"}></BrandCard>
                  </li>
                  <li class="brand-card">
                    <BrandCard content={"Website"}></BrandCard>
                  </li>
                  <li class="brand-card">
                    <BrandCard content={"Social Media"}></BrandCard>
                  </li>
                </div>
                <div className="brand-row">
                  <li class="brand-card">
                    <BrandCard content={"Emails"}></BrandCard>
                  </li>
                  <li class="brand-card">
                    <BrandCard content={"Pre-Events"}></BrandCard>
                  </li>
                  <li class="brand-card">
                    <BrandCard content={"YouTube"}></BrandCard>
                  </li>
                  <li class="brand-card">
                    <BrandCard
                      content={"Campus Ambassadors"}
                      size={"13px"}
                    ></BrandCard>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </section>

      <section class="sponsors-section">
        <div class="main">
          <div className="sub-title">
            <h2>Current Sponsors</h2>
          </div>
          <ul class="cards">
            <div className="cards-cards sponsers_cards">
              {cur_sponsors.map(function (sponsor) {
                return (
                  <li class="cards_item sponsers_item">
                    <SponsorCard imgSrc={sponsor} />
                  </li>
                );
              })}
            </div>
          </ul>
        </div>
      </section>

      <section class="sponsors-section">
        <div class="main">
          <div className="sub-title">
            <h2>Our Past Sponsors</h2>
          </div>
          <ul class="cards">
            <div className="cards-cards sponsers_cards">
              {sponsors.map(function (sponsor) {
                return (
                  <li class="cards_item sponsers_item">
                    <SponsorCard2 imgSrc={sponsor} />
                  </li>
                );
              })}
            </div>
          </ul>
        </div>
      </section>

      {/* <section className='contact bg-light py-5'>
            <div className="container text-dark">
                <div className='h1 text center mb-3'>

                    <h2 style={{ color: "#DE0000", font: "Barlow Condensed", textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", fontSize: "70px" }}>WHY TEDx IIT INDORE</h2>

                    <ul>
                        {
                            benefits.map(function (benefit) {
                                return (
                                    <div className='benefit'>
                                        <div className='benefit-image-div'>
                                            <img src={benefit.img} className="benefit-image" />
                                        </div>

                                        <h6 className='benefit-heading'>{benefit.title}</h6>
                                        <p className='benefit-text'>{benefit.summary}</p>
                                    </div>
                                )
                            })
                        }
                    </ul>

                </div>
                <div className='text center mb-3'>
                    <h2 style={{ color: "#DE0000", font: "Barlow Condensed", textAlign: "center", textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", fontSize: "70px" }}>Branding</h2>
                    <ul className='branding-list'>
                        {
                            branding.map(function (brand) {
                                return (
                                    <div className='brand'>
                                        <h6 style={{ color: "#000000", font: "Barlow Condensed", fontSize: "36px", boxShadow: "0px 4px 22px 0px #FF000045" }}>{brand}</h6>
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className='text center mb-3'>
                    <h2 style={{ color: "#DE0000", font: "Barlow Condensed", textAlign: "center", textShadow: "0px 4px 4px rgb(0,0,0,0.25)", fontSize: "70px" }}>
                        Our Past Sponsors
                    </h2>
                    <ul className='sponsors-list'>
                        {
                            sponsors.map(function (sponsor) {
                                return (
                                    <div className='sponsor-image-div'>
                                        <img src={sponsor} className="sponsor-image" />
                                    </div>

                                )
                            })
                        }
                    </ul>
                </div>

            </div>
        </section> */}
    </>
  );
}
