import React, { useEffect, useState } from "react";
import "./Ticket.css";
import { useNavigate } from "react-router-dom";
import {app} from '../../firebase'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage"
import LoadingOverlay from 'react-loading-overlay';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  borderRadius: 1,
  boxShadow: 24,
  py: 1,
  px: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  borderRadius: 5,
  boxShadow: 24,
  py: 1,
  px: 2,
  pb: 2,
};
export default function TicketPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => window.location.href = '/';
  const handleClose3 = () => setOpen3(false)
  const handleClose4 = () => setOpen4(false)
  const [name, setName] = useState('');
  const [age, setAge] = useState(0);
  const [occupation, setOccupation] = useState('');
  const [organization, setOrganization] = useState('');
  const [ticketType, setTicketType] = useState('normal');
  const [ticketEmail, setTicketEmail] = useState('');
  const [contact,setContact] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [amount, setAmount] = useState(0);
  const [transactionId, setTransactionId] = useState('')
  const [paymentReceiptUrl, setPaymentReceiptUrl] = useState(null);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [paymentReceipt, setPaymentReceipt] = useState(null)
  const [quantity,setQuantity] = useState(0);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if(name !== "" & ticketEmail !== "" & contact !== "" & transactionId !== "" & paymentReceipt !== null & ticketType !== null){
      setAllowSubmit(true)
    }
  }, [name,ticketEmail,contact,transactionId, paymentReceipt])
  // useEffect(() =>{
  //   if(age)
  // })
  const navigate = useNavigate();
  useEffect(() => {
    
    if(ticketType === "normal"){
      let value = 499;
      if(couponCode == "iiti15"){
        value = 0.85*value;
      }
      setAmount(value)
    }
    if(ticketType === "premium"){
      let value = 800;
      if(couponCode == "vipiiti12"){
        value = 0.88*value;
      }
      setAmount(value)
    }
  },[ticketType,couponCode]);

  async function handleSubmit(event){
    event.preventDefault();
    console.log(paymentReceipt)
    setIsActive(true)
    if (!allowSubmit) {
      setOpen(true);
    } else{
    const storage = getStorage(app);
    const ssRef = ref(storage,'ss/'+name+"-" +transactionId+"-"+paymentReceipt.name);
    await uploadBytes(ssRef, paymentReceipt).then((snapshot) => {
      console.log("upload done")
    })
    getDownloadURL(ref(storage,'ss/'+name+"-"+transactionId+"-"+paymentReceipt.name)).then((url) => {
      console.log(url)
      const formData = {
        'name': name,
        'email': ticketEmail,
        'contact': contact,
        'age':age,
        'couponCode': couponCode,
        'amount': amount,
        'paymentReceipt': url,
        'transactionId': transactionId,
        "organization": organization,
        "occupation":occupation,
        "ticketType":ticketType
      }
      console.log(formData)
      fetch('https://tedx-server.onrender.com/book',{
        mode: "cors",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(formData)
      }).then((res) => {
        if(res.status ===200){
          setOpen2("submitted")
          setIsActive(false)
          // navigate("/")
        }
        if(res.status === 400){
          setOpen3(true)
          setIsActive(false)
        }
      })
      
    })
    console.log(paymentReceiptUrl);
  }
  }
  return (
    
    <section className="merchPage flex justify-center">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="text-center md:w-[450px] w-[300px] bg-[#fff] grid grid-cols-1 gap-4 border-2 border-[#F55050]"
        >
          <div className="text-bold text-[#F55050] text-2xl">
            Please fill all the fields!!
          </div>
          <div onClick={() => setOpen(false)} className="w-100 text-center">
            {" "}
            <button className="text-white py-1 border-2 border-[#F55050] w-[100px] bg-[#F48484] rounded-md">
              OK
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open2 == "submitted"}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          className="bg-[#8e3ac9] md:w-[450px] w-[90%] grid grid-cols-1 gap-4 shadow-md items-center"
        >
          
          <div className="justify-center text-center text-white">
            <p>Thanks for booking our ticket!!</p><br/>
            <p>You will soon get a confirmation mail from our team regarding the payment.</p>
            <p>For any Issues mail tedx@iiti.ac.in</p>
          </div>
          <button className="text-lg font-semibold uppercase leading-tight truncate bg-cyan-700 hover:bg-cyan-900 text-white inline-block py-1 rounded-full tracking-wide" onClick={() => handleClose2()}>OK</button>
            
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          className="bg-[#8e3ac9] md:w-[450px] w-[90%] grid grid-cols-1 gap-4 shadow-md items-center"
        >
          
          <div className="justify-center text-center text-white">
            <p>An error occured!!!</p><br/>
            <p>Write to tedx@iiti.ac.in</p>
          </div>
          <button className="text-lg font-semibold uppercase leading-tight truncate bg-cyan-700 hover:bg-cyan-900 text-white inline-block py-1 rounded-full tracking-wide" onClick={() => handleClose3()}>OK</button>
            
        </Box>
      </Modal>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          className="bg-[#8e3ac9] md:w-[450px] w-[90%] grid grid-cols-1 gap-4 shadow-md items-center"
        >
          
          <div className="justify-center text-center text-white">
            <p>Enter Numeric Value in Contact and Age Fields</p><br/>
            
          </div>
          <button className="text-lg font-semibold uppercase leading-tight truncate bg-cyan-700 hover:bg-cyan-900 text-white inline-block py-1 rounded-full tracking-wide" onClick={() => handleClose4()}>OK</button>
            
        </Box>
      </Modal>
      <div>
        <form action="#" className="merch_form mt-8 grid grid-cols-6 gap-12">
          <div className="col-span-6 flex justify-center">
            <div>
              <p className="merch_head block text-5xl font-large text-white">
                Personal Details
              </p>
            </div>
          </div>
          <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-2">
            <label className="block text-3xl font-large text-white">Name*</label>

            <input
              value={name}
              type="text"
              name="name"
              placeholder="Enter Name"
              className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-4">
            <label className="block text-3xl font-large text-white">
              Contact Number*
            </label>

            <input
              value={contact}
              type="tel"
              required
              name="contact"
              pattern="[0-9]{10}"
              placeholder="Enter Contact Number"
              className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
              // onChange={(e) => setContact(e.target.value)}
              onChange={(e) => {
                // const value = e.target.value;
                if(!isNaN(+e.target.value)){
                  setContact(e.target.value)
                }else{
                  setOpen4(true);
                }
              }}
            />
          </div>

          <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-2">
            <label className="block text-3xl font-large text-white">
              Email (on which ticket will be sent)*
            </label>

            <input
              value={ticketEmail}
              type="text"
              name="ticketEmail"
              placeholder="TicketEmail"
              className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
              onChange={(e) => setTicketEmail(e.target.value)}
            />
          </div>
          <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-4">
          <label className="block text-3xl font-large text-white">Age</label>

          <input
            value={age}
            // type="text" pattern="^[0-9]*[.,]?[0-9]*$"
            type="text"
            name="age"
            placeholder="Enter Age"
            // onkeypress="return onlyNumberKey(event)"
            className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
            // onChange={(e) => setAge(e.target.value)}
            onChange={(e) => {
              // const value = e.target.value;
              if(!isNaN(+e.target.value)){
                setAge(e.target.value)
              }else{
                setOpen4(true);
              }
            }}
          />
        </div>
        <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-2">
          <label className="block text-3xl font-large text-white">
            Ticket Type*
          </label>

          <select
            value={ticketType}
            onChange={(e) => setTicketType(e.target.value)}
            className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
            // className="bg-gray-50 border border-[#9a2be9] text-gray-700 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected>Choose</option>
            <option value="normal">Normal</option>
            <option value="premium">Premium</option>
          </select>
        </div>
        <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-4">
          <label className="block text-3xl font-large text-white">
            Occupation
          </label>

          <input
            value={occupation}
            type="text"
            name="occupation"
            placeholder="Enter Occupation"
            className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
            onChange={(e) => setOccupation(e.target.value)}
          />
        </div>
        <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-2">
          <label className="block text-3xl font-large text-white">
            Organization/School
          </label>

          <input
            value={organization}
            type="text"
            name="organization"
            placeholder="Enter Organization/School"
            className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
            onChange={(e) => setOrganization(e.target.value)}
          />
        </div>
        <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-4">
            <label className="block text-3xl font-large text-white">
              Coupon Code
            </label>

            <input
              value={couponCode}
              type="text"
              name="Coupon Code"
              placeholder="Enter Coupon code"
              className="p-2 h-10 mt-1 w-full sm:w-[75%] rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>

          <div className="col-span-6 flex justify-center">
            <div><p className="merch_head block text-5xl font-large text-white">
              Payment
            </p></div>
          </div>
          <div className="col-span-6 align-center">
            <p className="mt-0 mb-0 block text-3xl font-large text-white">
              Amount: Rs {amount}/-
            </p>
          </div>
          <div className="payment_ways col-span-6 align-center">
            <p className="mt-0 mb-0 block text-4xl font-large text-white">
                QR For Payment:
            </p>
          </div>
          <div className="col-span-6 col-start-1 flex align-center sm:col-span-2 sm:col-start-3">
            <img
              className="rounded-xl border-[1px] border-[#9D28EB] shadow-[0_0_5px_5px_rgba(199,128,250,1.3)] m-1.5"
              src="/images/qr/QR_ticket.jpeg"
              alt="QR"
            ></img>
          </div>
          
          <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-2">
            <label className="block text-3xl font-large text-white">
              Screenshot of Payment
            </label>

            <input
              type="file"
              name="paymentReceipt"
              placeholder=""
              className="h-10 w-full rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
              onChange={(e) => setPaymentReceipt(e.target.files[0])}
            />
          </div>
          <div className="mt-2.5 col-span-6 sm:col-span-2 sm:col-start-4">
            <label className="block text-3xl font-large text-white">
              TransactionID
            </label>

            <input
              value={transactionId}
              type="text"
              name="transactionID"
              placeholder="Enter TransactionID of Payment"
              className="p-2 h-10 mt-1 w-full rounded-md border-[1px] border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-gray-600 text-xl text-gray-100"
              onChange={(e) => setTransactionId(e.target.value)}
            />
          </div>
          <div className="col-span-6 sm:flex sm:items-center sm:gap-4 w-100 flex justify-center items-center">
          <LoadingOverlay
  active={isActive}
  spinner
  text='Working on It'
  >
</LoadingOverlay>
            <button
              onClick={handleSubmit}
              // disabled={!allowSubmit}
              className="inline-block shrink-0 rounded-md border border-[#9D28EB] shadow-[0_0_2px_2px_rgba(199,128,250,1.3)] bg-[#9a2be9] px-12 py-3 text-3xl font-medium text-white transition hover:bg-transparent hover:text-[#9a2be9] focus:outline-none focus:ring active:text-blue-500"
            >
              Submit
            </button>
          </div>
        </form>
        
      </div>
    </section>
  );
}
