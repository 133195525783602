// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCf4-giSVkUxL1XgHG4IrQ6IYnnPNO28yA",
  authDomain: "merch-ss.firebaseapp.com",
  projectId: "merch-ss",
  storageBucket: "merch-ss.appspot.com",
  messagingSenderId: "286869744260",
  appId: "1:286869744260:web:27848cec7588d54b5430d1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

