import React from 'react'
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import AboutPage from "./pages/AboutPage/AboutPage";
import HomePage from "./pages/HomePage/HomePage";
import SpeakersPage from "./pages/SpeakersPage/SpeakersPage";
import TalksPage from "./pages/TalksPage/TalksPage";
import TeamPage from "./pages/TeamPage/TeamPage";
import MerchPage from "./pages/MerchPage/MerchPage";
import Footer from "./components/Footer/Footer";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from "react";
import NotFound from "./components/NotFound";
import ContactPage from "./pages/ContactPage/ContactPage";
import SponsorsPage from "./pages/SponsorsPage/SponsorsPage";
import TicketPage from './pages/TicketPage/Ticket';
import { Modal, ModalHeader } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './components/Merch/Merch.css'
function App() {

    const location = useLocation()
    const [page, setPage] = useState('');
    const [openMerch, setOpenMerch] = useState(true)
    const handleMerch = () => setOpenMerch(false)

    useEffect(() => {
        Aos.init({
            once: true,
            duration: '1000',
            anchorPlacement: 'bottom-center'
        })
        setPage(location.pathname.substring(1, location.pathname.length).split('/')[0])
    }, [location.pathname])
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }

    return (
        <>
            <div>
                {/* <Modal
                    show={openMerch}
                    onHide={handleMerch}
                    style={modalStyle}>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div class="merch_container container grid">
                            <div class="merch_data">
                                <h2 class="section_title about__title">Official Merchandize Available Now </h2>
                                <img src="/images/merch.png" alt="" class="merch_img"></img>
                                <p class="merch_description">Hi there! Are you looking for a comfortable and stylish t-shirt to add to your wardrobe? Well we have got exactly what you are looking for!</p>

                                <p class="merch_description">Our t-shirts are made from soft and 100% cotton fabric that feels great against your skin. They're style statements in themselves. Cozy inside and elegant outside. Plus, they're easy to care for and can be worn again and again!</p>

                                <p class="merch_description">So why not add one of our t-shirts to your collection today?
                                    Order now @ ₹439 </p>

                                <Link to="/merch" className='button-11' onClick={handleMerch}>Buy now</Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}
                <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
                    { <Navbar currentPage={page} /> }

                    <div style={{ flex: 1 }}>


                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path='/about' element={<AboutPage />} />
                            <Route path='/speakers' element={<SpeakersPage />} />
                            <Route path='/talks' element={<TalksPage />} />
                            <Route path='/team' element={<TeamPage />} />
                            {/* <Route path='/gallery' element={<GalleryPage />} /> */}
                            <Route path='/contact' element={<ContactPage />} />
                            <Route path='/sponsors' element={<SponsorsPage />} />
                            {/* <Route path='/merch' element={<MerchPage />} /> */}
                            <Route path='/ticket' element={<TicketPage />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default App;
