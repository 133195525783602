export const speakers = [
    {
        sno: "0",
        name: "",
        post: "",
        description: "",
        date: "",
        imgPath: ""
    },

    {
        sno: "1",
        name: "Gauranga Das",
        post: "Leader at Govardhan Eco Village and Preacher at ISKCON",
        description: "With an undergraduate degree in Metallurgical Engineering from the reputed IIT Mumbai, he is leading the Govardhan Eco Village Facilities, Nursery and the Green Buildings Construction(CSEB). He has bagged many awards for GEV by presenting the Symbiotic waste management systems followed in GEV in various reputed conferences like CII, SKOCH and FICCI. He is instrumental in bringing up the new Vedic culture and education center in GEV. He is also currently leading the much awaited Vrindavan Biodiversity project at GEV.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Gauranga-Das.webp"
    },
    {
        sno: "2",
        name: "Karan Chitra Deshmukh",
        post: "DRUMMER | PERCUSSIONIST | MUSICIAN",
        description: "Karan Chitra Deshmukh is a percussionist, electronic fusion artiste based in Mumbai. Karan Chitra Deshmukh has established himself as a spokesman for a generation of music that incorporates electronica, dance grooves, and classical Indian sound. He is an artist with a versatility of Indian music, tabla, percussion, and Western drumming -- who plays a unique hybrid East-West drum set up. He's an open- minded musician who improvises rock, electronica, and Indian classical music. A dazzling percussionist, an accessible entertainer.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Karan-Chitra-Deshmukh.webp"
    },
    {
        sno: "3",
        name: "Mahesh Bhat",
        post: "Photographer and Film Maker",
        description: "He has been commissioned by publications from over twenty countries in his career which has spanned over 30 years. His work has spanned a variety of genres such as advertising, interior and architecture, corporate, portraiture, documentary and photojournalism. Along with his professional practice, he enjoys working at \"Srishti Institute of Art, Design and Technology-Bangalore.\" He was one of the four photographers to work on the 'Re-Imagining the People of India' under the aegis of the inaugural Neel Dongre Grant (2013). His work has featured in prominent exhibitions like CLICK - Contemporary Photography in India at Vadhera Gallery -Delhi and London (2008), Goa International Photo Festival (2015), Dali International Photo Festival, China (2015) and India Photo Festival at Hyderabad (2015) to name a few.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Mahesh-Bhat.webp"
    },
    {
        sno: "4",
        name: "Manan Desai",
        post: "Stand-up comedian and Founder of The Comedy Factory",
        description: "Manan Desai is a popular Indian Stand-up comedian, who is appeared in the popular comedy show Comedy Night Bachao Season 2. He gave up a career as a Radio Jockey after working for 7 years as a prime-time jock in some of the leading private radio stations of Gujarat. All for the passion of bringing live comedy entertainment in Gujarat. As a performer, Manan loves to weave content that talks about the cultural nuances of India. He strongly believes that every aspect of our diverse culture has a lot of comedy to offer.He is one of the founders of The Comedy Factory.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Manan-Desai.webp"
    },
    {
        sno: "5",
        name: "Mathew Jose",
        post: "Entrepreneur and Founder at Paper Man",
        description: "Mathew Jose is a social entrepreneur, who is championing the cause of recycling by his social venture called Paperman. This agency is almost making a paper revolution in Chennai, by connecting over 280 local scrap dealers to 3,500 households to ensure scrap is collected, weighed and paid. Paperman charges the kabaddi Wala 5 percent of the total value of each transaction as revenues. They have also connected NGO's to people who are willing to donate papers and plastics. The NGO's, in turn, utilizes this to raise funds by selling them.He has also featured in Forbes 30 under 30 India.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Mathew-Jose.webp"
    },
    {
        sno: "6",
        name: "Rajani Thindiath",
        post: "Editor and Author at Tinkle",
        description: "She has years of experience in publishing, she has ideated, plotted and scripted original stories of fiction; researched, written and edited features; conceived new comic characters; and given a fresh look to an existing magazine/book.In the course of this journey, she has been a writer/sub writer at Navneet publications for four years, an assistant editor at Tinkle(India's most popular English -language monthly comics magazine for children) over 4 years and took over as magazines editor in 2011",
        date: "03-26-2017",
        imgPath: "/images/speakers/Rajani-Thindiath.webp"
    },
    {
        sno: "7",
        name: "Samit Choksi",
        post: "Innovator and Founder at Thinkphi",
        description: "Samit Choksi is the Co-founder of Thinkphi a clean technology company innovating sustainable engineered products that help intelligently conserve water, energy, and waste. Prior to this Samit has co-founded and worked at various start-ups in India and Silicon Valley as a software architect and developer. He has worked closely on product development at a Fortune 500 data base technology company while living in the USA. Spending most of his life outside India, upon moving back he was motivated to build products that aspire to have global standards in quality and finish. With a love for nature and the environment, he is currently realizing his dream of building simple but intelligent products that influence user behavior to conserve earth's natural resources. Thinkphi's first product, Thinkphi is a patented system which smartly converges water harvesting and renewable energy for open spaces in smart cities, industries, or large campuses.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Samit-Choksi.webp"
    },
    {
        sno: "8",
        name: "Sanjeev Newar",
        post: "Analytics and Risk Management",
        description: "He is an MBA graduate from IIM Calcutta and engineering undergrad from IIT Guwahati - two of the most respected institutions of India. Professionally he is an expert in Analytics and Risk Management. In past 14 years of his career, he has undertaken several projects of social, national and global importance. He is also associated with IIT Kanpur in nurturing budding entrepreneurs and indigenous innovation from grass-root level. He is the CEO of Aarsh Management which was listed in 20 Most Promising Risk Management Solution Providers of the world by CIO Review.",
        date: "03-26-2017",
        imgPath: "/images/speakers/Sanjeev-Newar.webp"
    },

    {
        sno: "9",
        name: "Dr. Prasun Chatterjee",
        post: "Specialist and Assistant Professor of Geriatrics Department, AIIMS New Delhi",
        description: "Dr. Prasun Chatterjee is one of the very few qualified Geriatrician (specialization in elderly care medicine ) in India. He is currently faculty in AIIMS, New Delhi, Deptt. Of Geriatric Medicine. He has started N.G.O “Healthy Aging India” and is heading it to make it an NGO of International reputation. He is the founder of an intergenerational learning center at Noida Purva Madhyamik Vidyalaya, where educated elderly are mentoring less privileged school children - a 1st of its kind initiative in India. He was given Best Paper Award in National conference in Kolkata 2009 for working on Frailty in elderly, awarded CSIR fellowship to work on Sarcopenia, awarded by Padma Shri Professor V S Natarajan for serving elderly in the community and also \"Bharat Jyoti \"Award 2018 for selfless service to the community.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Prasun-Chatterjee.webp"
    },
    {
        sno: "10",
        name: "Lt. Gen. Satish Dua",
        post: "Chairman Chiefs of Staff Committee of the Indian Armed Forces",
        description: "Lt. was commissioned into 8 Jammu and Kashmir Light Infantry (Siachen) in December 1979. He is considered as a counter-terrorism specialist and has held various important command, staff and instructor appointments during his career. He has commanded a battalion and a brigade in counterterrorism operations in Jammu and Kashmir and the XV Corps (Srinagar) (2015-2016). As a Major General of Assam Rifles, he was instrumental in raising a new formation termed IGAR (East) in Northeast India. He has held various staff appointments including Colonel MS (Complaints) in Military Secretary Branch; Brigade Major of Kargil Brigade; Brigadier General Staff (Operations) of XVI Corps (Nagrota); Deputy Director General in Weapon Equipment Directorate; Additional Director General (Procurement) in Master General of Ordnance Branch. During his career, he has been awarded the Vishisht Seva Medal, Sena Medal, the Uttam Yudh Seva Medal (2017) and Param Vishisht Seva Medal (2018) for his service.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Lt-Gen-Satish-Dua.webp"
    },
    {
        sno: "11",
        name: "Mr. Achanta Sharath Kamal",
        post: "Professional Indian table tennis player (Current World Rank: 32)",
        description: "Achanta Sharath Kamal is a professional table tennis player from Tamil Nadu, India. He is the second Indian table tennis player to become 8 times national champion. His current world rank is 35, as of September 2018. He has won the men's singles gold in the 16th Commonwealth table tennis championship held at Kuala Lumpur in 2004. He has won the US Open Table Tennis Men's championships held at Grand Rapids, Michigan in July 2010. He is the winner of 4 gold, 2 bronze and a silver medal in Commonwealth games. He recently bagged bronze medal for India in the Asian games 2018. He is a recipient of the Arjuna award for the year 2004. He currently lives in Düsseldorf, Germany. He is also employed with the Indian Oil Corporation as an officer.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Sharath-Kamal.webp"
    },
    {
        sno: "12",
        name: "Mr. Ashish Jaiswal",
        post: "Author of \"Fluid\", \"True Dummy\" and \"How to Reform a Business School",
        description: "Ashish Jaiswal is a writer and an internationally renowned expert on education. He is currently a fellow at the Oxford Centre for Higher Education Policy Studies, UK and the author of upcoming book Fluid: The Approach Applied by Geniuses Over Centuries. Ashish has spoken around the world on the new age reforms required in our learning and thinking in the light of mega changes currently being witnessed in technology, markets, and human behavior. Ashish is also the founder of iHER (India for Higher Education Reforms), a community platform with over 100,000 of students, educationists and policymakers with the aim to discuss transformative ideas on education. Ashish holds a Ph.D. and a Master's from the University of Oxford in the field of education.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Ashish-Jaiswal.webp"
    },
    {
        sno: "13",
        name: "Mr. Divyanshu Damani",
        post: "Entrepreneur, social media influencer, public figure",
        description: "Divyanshu Damani is a social media influencer, entrepreneur, public speaker, vlogger, traveler, scuba diver, skier, and a believer. He currently has a family of over 200K followers on social media and has inspired thousands of young people across the globe. He is a TEDx speaker and actively speaks at Josh Talks. He has done spoken at over 50 other speaking platforms.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Divyanshu-Damani.webp"
    },
    {
        sno: "14",
        name: "Ms. Neeti Leekha Chhabra",
        post: "Founder & President of NGO Yes to Life; Faculty-HR & OB; Corporate Trainer; Motivational Speaker",
        description: "Founder and president of an NGO 'Yes to Life', Neeti is working relentlessly towards spreading awareness for breast cancer and supporting those affected by it. The young breast cancer winner started the NGO in 2014 with an aim to contribute to lowering the mortality rate of those diagnosed with it. A professor by profession, she left her full-time dream job to pursue her inner calling. She now balances her time between Yes to Life and her career as a corporate trainer & professor. Also an author, she writes about life, positivity, and happiness. Neeti is a public speaker who doesn't only talk about cancer awareness but also about stress management and happier lifestyles. She has delivered awareness sessions to diverse audiences at various places, including LinkedIn, IIM Indore.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Neeti-Leekha-Chhabra.webp"
    },
    {
        sno: "15",
        name: "Ms. Rinku Sawhney",
        post: "Success coach from over a decade",
        description: "Rinku is a Social Impact Entrepreneur, a Personal Excellence & Mind-shift Coach. She has co-founded Elevated Minds, which is a start-up committed to help people rise above mediocrity and become the masters of their own lives. Over 5000 people have experienced Rinku's signature live events. Her work has enabled people to get clarity and identify roadblocks, that have held them back from achieving success. Rinku firmly believes that each and every human is unique and special and that we are all born to be successful. She is also the author of an upcoming book, “My Life; I Decide”. In her own small way, Rinku believes in impacting the world and leaving it a slightly better place.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Rinku-Sawhney.webp"
    },
    {
        sno: "16",
        name: "Swami Prem Parivartan AKA Peepal Baba",
        post: "Founder- Give Me Trees Trust | Nature Enthusiast | Green Activist",
        description: "Swami Prem Parivartan (aka Peepal Baba) is the founder trustee of the environmental movement \"Give Me Trees\" Trust. He has been planting trees for the last 41 years since he was 11 years old. He is fondly called 'Peepal Baba' because he has devoted his entire life towards the planting and preservation of Peepal trees, facilitating the plantation of more than 10 million Peepal trees all across India. He is the founder of Give Me Trees Trust, which is one of the largest tree plantation and preservation movements in India. Give Me Trees Trust has planted more than 20 million trees all across India in the last 41 years. He has received numerous awards, including the Unsung Heroes Award presented to him by John Abraham.",
        date: "10-21-2018",
        imgPath: "/images/speakers/Peepal-Baba.webp"
    },
    {
        sno: "17",
        name: "CA Rachana Ranade",
        post: "Chartered Accountant, Youtuber and Teacher",
        description: " She is also a Youtuber with a channel named 'CA Rachana Phadke Ranade' that she started in 2015 which has over 3.5 million subscribers. She is a CA certified by the The Institute of Chartered Accountants of India. She also holds a PG Diploma in Business Management, Business Management and a Master's degree in Business Studies from Savitribai Phule Pune University.",
        date: "09-04-2022",
        imgPath: "/images/speakers/CA-Rachana-Ranade.webp"
    },
    {
        sno: "18",
        name: "Dr. Sarita Ahlawat",
        post: "The cofounder of botlab dynamics Pvt Ltd.",
        description: "She is the head of Living Science Group, a science communication platform, and is also the founding director of Phase Labs. She is also the CEO of Aerogram, a startup devising a network to predict real-time air quality in a local mapped area. In this episode, she shares her experience behind the startup and her efforts in the detection of air quality of IIT Delhi.",
        date: "09-04-2022",
        imgPath: "/images/speakers/Sarita-Ahlawat.webp"
    },
    {
        sno: "19",
        name: "Mr. Nirmal N.R.",
        post: "CEO of Zoomcar India",
        description: "Entrepreneurial leader with 14 years of experience with a strong record in Setting up new businesses, Scaling up operations, Strategy development, goal setting & Implementation.He currently holds a bachelor's degree from NIT Calicut and has completed a Post Graduate program in Management, Strategy and leadership from the Indian School of Business.",
        date: "09-04-2022",
        imgPath: "/images/speakers/Nirmal-NR.webp"
    },
    {
        sno: "20",
        name: "Mr. Suresh Manglani",
        post: "CEO of Adani Total Gas Ltd",
        description: "Suresh is a seasoned professional with 29 years of varied experience in diversified businesses. He has immense experience in Oil & Gas projects & major specialization in conceptualizing, negotiating, forming & successfully operating City Gas Distribution businesses and joint ventures",
        date: "09-04-2022",
        imgPath: "/images/speakers/Suresh-Manglani.webp"
    },
    {
        sno: "21",
        name: "Mr. Saumesh Panday",
        post: "YouTuber and Photographer",
        description: "Soumesh Pandey is a Travel Photographer and Youtuber, who tells stories through his work. Worked with brands like Kiamotors, Sam sung, HP and many more. Represented India at World vlog challenge at Everest base camp. His photograph also Got featured on Instagram's official account",
        date: "09-04-2022",
        imgPath: "/images/speakers/Saumesh-Panday.webp"
    },
    {
        sno: "22",
        name: "Yagnesh Sanghrajka",
        post: "Founder and CFO, 100X.VC",
        description: "Mr Yagnesh Sanghrajka is a founder and CFO of 100X.VC. He is also an investor and CFO at Global Education Solutions. He has 25+ years work experience in Global VC/PE Fund and various service industries. His education qualifications include a Bachelor of Com merce Accounting Technology/Technician and Bookkeeping from Podar College of Commerce and Economics and and ACA, Chartered Accountancy from The Institute of Chartered Accountants of India.",
        date: "09-04-2022",
        imgPath: "/images/speakers/Yagnesh-Sanghrajka.webp"
    },
    {
        sno: "23",
        name: "Amit Borkar",
        post: "Founder, EDSCOPE",
        description: "Mr Amit Borkar is a pioneer in the field of education in the Metaverse. Edscope, a com pany he co-founded, is a Knowledge MetaVerse for learning, an avatar-based im mersive platform that enables real-time learning & collaboration in a 4D environ ment. It is a futuristic platform designed for schools, universities, institutions, educators, teachers and students to experience, share, collaborate and acquire knowledge. He has had a successful career spanning 18 years in the Information Technology industry.",
        date: "09-04-2022",
        imgPath: "/images/speakers/Amit-Borkar.webp"
    },
    {
        sno: "24",
        name: "Col. Rajeev Bharwan",
        post: "Ex Indian Army Colonel",
        description: "Colonel Rajeev Bharwan is an accomplished, seasoned, professionally trained, and result-oriented Indian Army Colonel with 21 years of experience. He has substantial exposure to strategic planning and decision-making, crisis management and direct action missions with vast experience in counter-terrorism and Counter-Insurgency in active combat zones in Assam, Manipur, Nagaland, and Jammu and Kashmir. He is An officer with an excellent professional attitude and a reputation for moving even dead stones. ",
        date: "04-02-2023",
        imgPath: "/images/speakers/Rajeev-Bharwan.webp",
        facebook: "https://www.facebook.com/rajeev.bharwan.3/",
        linkedIn: "https://www.linkedin.com/in/rajeev-bharwan/",
    },
    {
        sno: "25",
        name: "Dr. Richa Singh",
        post: "Environmentalist",
        description: "Dr. Richa Singh completed her doctoral research from IIT Bombay on management strategies and technologies for municipal solid waste and hazardous wastes. She holds a master’s degree, with a gold medal in Environmental Science and Technology from BHU. She has worked with Ramky Enviro Engineers Limited (now re-sustainability ltd) at their commercial hazardous waste treatment, storage, and disposal facility in Indore for nearly two years.",
        date: "04-02-2023",
        imgPath: "/images/speakers/Richa-Singh.webp",
    },
    {
        sno: "26",
        name: "Ananya Birla",
        post: "Entrepreneur and Singer",
        description: "Ms.Ananya Birla is an Indian singer, songwriter and entrepreneur. Birla is the founder of Svatantra Microfin, a company that provides microfinance to women in rural India. Birla has received awards for her work and entrepreneurship, including the ET Panache Trendsetters of 2016 award for Young Business Person and was listed as one of GQs Most Influential Indians of 2018.",
        date: "04-02-2023",
        imgPath: "/images/speakers/Ananya-Birla.webp",
        facebook: "https://www.facebook.com/AnanyaBirlaOfficial/",
        twitter: "https://twitter.com/ananya_birla",
    },
    {
        sno: "27",
        name: "Ashu Ghai",
        post: "Youtuber and Educationalist",
        description: "Ashu Ghai is an Indian teacher who is known for his unique style of teaching, especially the topics related to science. He began sharing his knowledge through Youtube during the lockdown period. Later, when YouTube came up with the feature of shorts, he started his YouTube Channel Named ‘Science and fun’ where he started posting shorts explaining concepts of day to day life in a much simpler way.",
        date: "04-02-2023",
        imgPath: "/images/speakers/Ashu-Ghai.webp"
    },
    {
        sno: "28",
        name: "Dr. Varun Kapoor",
        post: "ADGP, Indore",
        description: "Dr Varun Kapoor is a senior IPS officer. He is currently ADGP, Rustamji Armed Police Training College, Indore. He has done his BE (Honors) in Mechanical Engineering – NIT Trichy (Tamil Nadu). He joined India Police Service in 1991 and was allotted Madhya Pradesh Cadre. He was DIG in three ranges – Chhatarpur, Ratlam, Ujjain, and ADG in one zone – Indore. His other posting includes IGP, Police Radio Training School, Indore; ADGP, Narcotics, Indore.",
        date: "04-02-2023",
        imgPath: "/images/speakers/Varun-Kapoor.webp",
    },
    {
        sno: "29",
        name: "Praveen Kumar",
        post: "Indian Cricket Team Bowler",
        description: "Praveen Kumar is a former Indian cricketer who bowled right-arm medium-pace. He made his One Day International cricket debut for the Indian National Cricket Team in November 2007. Kumar was known for his ability to swing the ball both ways which made him the premier opening bowler for India in ODIs. Kumar was the joint-highest wicket-taker in the 2004-05 Vijay Hazare Trophy, which is India's domestic 50-over tournament.",
        date: "04-02-2023",
        imgPath: "/images/speakers/Praveen-Kumar.webp",
    },
    {
        sno: "30",
        name: "Rahul Bhargava",
        post: "Founder at Shekunj.com",
        description: "Rahul Bhargava is the Managing Director at R Dot Ventures, founder at Shekunj.com and founder at Octahire.com. R Dot Ventures, a brand initiated in 2015 with the vision to change the concept of service industry headquartered in Indore, comprising 3 business verticals, includes Education-skill development, Recruitment and Audio & Acoustical Industry with 1500+ freelancers, 5 franchisees and a community of more than 16000 women.",
        date: "04-02-2023",
        imgPath: "/images/speakers/Rahul-Bhargava.webp",
    },
]