import React from "react"
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faLinkedin, faYoutube, faFacebook, faGoogle,} from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom"


function Footer() {
    return (

        <footer className="site-footer" data-aos="fade-up">
            <div className="container">
                <div className="row">

                    <div className="col-xs-6 col-md-3 text-center text-md-start">
                        <h6>Useful Links</h6>
                        <ul className="footer-links">
                            <li><a target={'_blank'} rel='noreferrer' href="https://tedx.com/">TEDx</a></li>
                            <li><a target={'_blank'} rel='noreferrer' href="https://iiti.ac.in/">IIT Indore</a></li>
                            <li><a target={'_blank'} rel='noreferrer' href="http://gymkhana.iiti.ac.in/">IIT Indore Students Gymkhana</a></li>

                        </ul>
                    </div>

                    <div className="col-xs-6 col-md-3 text-center text-md-start">
                        <h6>Quick Links</h6>
                        <ul className="footer-links">
                            <Link to='/about' ><li>About</li></ Link>
                            <Link to='/speakers' ><li>Speakers</li></ Link>
                            <Link to='/talks' ><li>Talks</li></ Link>
                            <Link to='/team' ><li>Team</li></ Link>
                            {/* <Link to='/contact' ><li>Contact Us</li></ Link> */}
                        </ul>
                    </div>

                    <div className="col-xs-6 col-md-3 text-center text-sm-center">
                        <h6>Social Media</h6>

                        <div className="social_div">
                            <ul className="social-icons">
                                <li><a href="https://www.youtube.com/user/TEDxTalks/videos"><i><FontAwesomeIcon icon={faYoutube} size= '2x'/></i></a></li>
                                <li><a href="https://twitter.com/tedxiitindore"><i><FontAwesomeIcon icon={faTwitter} size= '2x'/></i></a></li>
                                <li><a href="https://www.instagram.com/tedxiiti/"><i><FontAwesomeIcon icon={faInstagram} size= '2x'/></i></a></li>
                                <li><a href="https://www.linkedin.com/company/tedx-iitindore/"><i><FontAwesomeIcon icon={faLinkedin} size= '2x'/></i></a></li>
                                <li><a href="https://www.facebook.com/tedxiitindore/"><i><FontAwesomeIcon icon={faFacebook} size= '2x'/></i></a></li>
                                <li><a href="https://mail.google.com/mail/?view=cm&fs=1&to=tedx@iiti.ac.in"><i><FontAwesomeIcon icon={faGoogle} size= '2x'/></i></a></li>
                            </ul>
                        </div>    
                        
                    
                        
                    </div>

                    <div className="col-xs-6 col-md-3 text-center text-md-start">
                        <img className="tedx_img" src="/images/logos/white tedx.png" alt="" />
                    </div>

                </div>
                <hr />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 text-center">
                        <p className="copyright-text"> &copy; 2024 TEDxIITIndore
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer