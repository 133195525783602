import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";

export default function MultiLayerParallax() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "200%"]);

  return (
    <div
      ref={ref}
      className="w-full h-screen overflow-hidden relative grid place-items-center"
    >
      <motion.h1
        style={{ y: textY }}
        className="mt-[100px]  text-white  relative z-30"
      >
        <p className="font-bold mt-[-100px] text-white text-[15px] xxs:text-[15px] xs:text-[20px] sm:text-[30px] md:text-[40px] lg:text-[70px] relative z-10">

          TED<sup>x</sup>IIT Indore

        </p>
        <p className="mt-[-10px]  tracking-widest">
          x = Independently organized event
        </p>

        <p className="font-bold mt-[-10px] text-white text-[40px] xxs:text-[50px] xs:text-[70px] sm:text-[80px] md:text-[90px] lg:text-[100px] relative z-50">

          Trajectory to the Pinnacle
        </p>
        <div className="flex justify-between text-3xl w-[60%] mx-auto">
          <div className="">location: IIT Indore</div>
          <div >Date: 14 April 2024</div>
        </div>

        <a href="https://forms.gle/4UWj9FkSNt2yKf3R8" target="_blank" className="px-4 py-6 rounded-2xl shadow-xl text-[20px] mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold ">
          Book Ticket
        </a>

        {/* <div 
        style={{  
  backgroundImage: `url(/images/home/peakpx.jpg)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}
        >
          bfsklfbklsndkfns
        </div> */}
      </motion.h1>



      <motion.div
        className="absolute inset-0 z-0"
        style={{
          // backgroundImage: `url(/images/home/LANDSCAPE.png)`,
          backgroundImage: `url(/images/home/peakpx.jpg)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundY,
        }}
      />
      <div
        className="absolute inset-0 z-20"
        style={{
          // backgroundImage: `url(/images/home/jnt.png)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
      <div
        className="absolute inset-0 z-20"
        style={{
          // backgroundImage: `url(/images/home/jt2.png)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />

    </div>
  );
}