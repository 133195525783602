import './BrandCard.css'

export default function SponsorCard({content,size="18px"}) {
    return (
      <div class="box">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div class="content">
          <h2 style={{"fontSize":size}}>{content}</h2>
        </div>
      </div>
    )
};