import React, { useEffect} from 'react'
import CuratorCard from '../../components/TeamCard/CuratorCard';
import NewTeamCard from '../../components/TeamCard/NewTeamCard';
import OnlyHead from '../../components/TeamCard/OnlyHead';
import { Organizer } from '../../data/organizer_details'
import { lazyload } from 'react-lazyload';
import { teams } from '../../data/teams_sorted'
import './TeamPage.css'

export default function TeamPage() {



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        
        <section className='team py-5'>
            <div className='teampage'>
            
            {Organizer.map(function(org,i){
                return <CuratorCard teamInfo={org}/>
            })}
            <lazyload>
            {teams.map(function(team,i){
                if(i<6){
                    return <NewTeamCard teamInfo={teams[i]}></NewTeamCard>
                }
            })}
            </lazyload>
            <OnlyHead teamInfo={teams}></OnlyHead>
            </div>
        </section>

    );
}

