import React, { useEffect } from 'react'
import './AboutPage.css'

export default function AboutPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className='about py-5'>
            <div className="container text-light">
                <div className="h1 text-center mb-3">About Us</div>
                <div className="row about_top">
                    <div className="col-12 col-md-5 p-3 pipe" data-aos="fade-up">
                        <div className="h2 ps-2 mb-2">TED</div>
                        <p>
                            TED is a nonprofit devoted to spreading ideas, usually in the form of short, powerful talks (18 minutes or less). TED began in 1984 as a conference where Technology, Entertainment and Design converged and today covers almost all topics from Science to Business to Global Issues - in more than 100 languages.
                        </p>
                        <p>
                            TED is owned by a nonprofit, nonpartisan foundation. Their agenda is to make great ideas accesible and spark conversation.
                        </p>
                    </div>
                    <div className="col-12 col-md-7 p-3" data-aos="fade-up">
                        <div className="h2 ps-2 mb-2">TED<sup className='text-danger'>x</sup> </div>
                        <p className='x fw-bold'> x = Independently organised event</p>
                        <p>
                            TEDx was created in 2009 in the spirit of TED's mission, "Ideas worth spreading". It supports independent organizers who want to create TED-like event in their own community.
                        </p>
                        <p>
                            In the spirit of ideas worth spreading, TEDx is a program of local, self-organized events that bring people together to share a TED-like experience. At a TEDx event, TEDTalks video and live speakers combine to spark deep discussion and connection in a small group. These local, self-organized events are branded TEDx, where x = independently organized TED event. The TED Conference provides general guidance for the TEDx program, but individual TEDx events are self-organized (subject to certain rules and regulations).
                        </p>
                    </div>

                </div>
                <div className="row my-5">

                    <div className="col-12 p-3" data-aos="fade-up" data-aos-delay='300'>
                        <div className="h2 ps-2 mb-2">Theme: Atomic to Cosmic</div>
                        <p>The pursuit of excellence is a path that very few can really follow. The obstacles and  hardships along the way can make or break a human. The changes required to break through are cosmic in size. Yet, the ones who succeed shine like a star amongst the ordinary.</p>
                        <p>
                        Everywhere they go, they are recognised for their achievements and hard work. Every decision they take is a lesson for us to learn from. Their journey, an inspiration; their success, a tribute to their genius. Throughout their journey, they tackle problems in ways thought to be unfeasible. They display a degree of mastery over their skills previously that can only be considered extraordinary at the very least.
                        </p>
                        <p>
                        This TEDx, we've invited master innovators of several domains to come forth and showcase their journey as an culmination of atomic steps on their way to cosmic success.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
