import React from "react";

function Button({text}) {
  return (
    <div>
      <a
        className="bg-gradient-to-r from-violet-400 to-gray-900 shadow-md inline-flex items-center rounded-full border border-[#0d9488]  px-8 py-3 text-white hover:scale-110 focus:outline-none focus:ring"
      >
        <span className="text-sm font-large  md:text-5xl"> {text} </span>

        <svg
          className="ml-3 h-9 w-9"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      </a>
    </div>
  );
}

export default Button;
