export const Organizer = [
    {
        "team": "Organizing Team",
        "description": "They decide who will speak at the event.They also help in overall cooperation between the organizers and the respective teams.",
        "head": {
            "sno": 1,
            "name": "Sahil Singh Shekhawat",
            "team": "Organizer",
            "linkedin": "http://linkedin.com/in/sahil-singh-shekhawat-157924227",
            "instagram": "https://instagram.com/a_moltres?igshid=YmMyMTA2M2Y=",
            "head": true,
            "email": "ce200004043@iiti.ac.in",
            get img() { return '/images/team/' + this.name + '.webp' }
        },
        "cohead": {
            "sno": 33,
            "name": "Manisha Devi",
            "team": "Co-Organizer",
            "linkedin": "https://www.linkedin.com/in/manisha-sahu-5575b9205",
            "instagram": "https://www.instagram.com/mani_shail/",
            "head": false,
            "email": "me200003046@iiti.ac.in",
            get img() { return '/images/team/' + this.name + '.webp' }
        },
        "count": 3,
        "members": [

            {
                "sno": 20,
                "name": "Maitreya Pathak",
                "team": "Curator",
                "linkedin": "https://www.linkedin.com/in/maitreyapathak/",
                "instagram": "https://www.instagram.com/maitreya2703/",
                "head": false,
                "email": "mems210005027@iiti.ac.in",
                get img() { return '/images/team/' + this.name + '.webp' }
            },
            {
                "sno": 16,
                "name": "Mansi Choudhary",
                "team": "Curator",
                "linkedin": "https://www.linkedin.com/in/mansi-choudhary-49b945231",
                "instagram": "https://instagram.com/_mansichoudhary17_?igshid=ZDdkNTZiNTM=",
                "head": false,
                "email": "ce210004025@iiti.ac.in",
                get img() { return '/images/team/' + this.name + '.webp' }
            },
            {
                "sno": 44,
                "name": "Himanshu Gupta",
                "team": "Curator",
                "linkedin": "https://www.linkedin.com/in/himanshu-gupta-4a5942201/",
                "instagram": "https://www.instagram.com/_himanshu1817/",
                "head": false,
                "email": "ce200004018@iiti.ac.in",
                get img() { return '/images/team/' + this.name + '.webp' }
            }
        ],
    }
]