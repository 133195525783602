import {speakers} from './speakers'
import {talks} from './talks'
import {gallery} from './gallery'
import {teams} from './teams'

export default function Data() {
    
    const data = {speakers, talks, gallery, teams}

    return data
}
