import React from 'react';
import './CircularCard.css';

export default function CircularCard({props, children, icon}) {
    return (
        <div class="container">
           <div class="row">
              <div class="card card-circle">
                 <div class="card-icon">
                   <i class={icon}></i>
                 </div>
                 <div class="card-body">
                   <h5 class="card-title">{props}</h5>
                   <p class="card-text">{children}</p>
                   {/* <a href="#" class="btn btn-primary">Eat me</a> */}
                 </div>
             </div>
           </div>
        </div>
    )
};


