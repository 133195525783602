import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./Navbar.css"
import MenuDrawer from "../MenuDrawer"
import { useEffect } from "react"
import Announcement from "../Announcement/Announcement"

function Navbar({ currentPage }) {
    const [scroll, setScroll] = useState(0);
    useEffect(() => {

        var dot = '.'
        const allEl = document.querySelectorAll('.navlink')
        if (currentPage === '') {
            dot += 'home'
            document.querySelector('.header').style.position = 'fixed'
            //  document.querySelector('.header').classList.add('bg-transparent')
            // document.querySelector('.header').classList.remove('back')
            // document.querySelector('.logo').style.display = 'none'
            for (let index = 0; index < allEl.length; index++) {
                const element = allEl[index];
                element.classList.remove('backg')
            }
        }

        else {
            dot += currentPage
            document.querySelector('.header').classList.remove('bg-transparent')
            document.querySelector('.header').classList.add('back')
            document.querySelector('.header').style.position = 'sticky'
            document.querySelector('.logo').style.display = 'flex'

            for (let index = 0; index < allEl.length; index++) {
                const element = allEl[index];
                element.classList.remove('backg')
            }
            const el = document.querySelector(dot)
            if (el) {
                el.classList.add('backg')
            }
        }
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                document.querySelector('.header').classList.remove('bg-transparent')
                document.querySelector('.header').classList.add('opacity-80')
                document.querySelector('.navlink').classList.remove('opacity-80');
                document.querySelector('.navlink').classList.remove('nav-div')
                document.querySelector('.header').classList.add('back')
                document.querySelector('.logo').style.display = 'flex'
            }
            else {
                document.querySelector('.header').classList.add('bg-transparent')
                document.querySelector('.header').classList.remove('opacity-80')
                document.querySelector('.header').classList.remove('back')
                document.querySelector('.logo').style.display = 'none'
            }
        })


        // const el = document.querySelector(dot)
        // if (!el) return
        // document.querySelector('.header').classList.remove('bg-transparent')
        // document.querySelector('.header').classList.add('bg-dark')
        // document.querySelector('.header').style.position = 'sticky'
        // document.querySelector('.logo').style.display = 'flex'
        // for (let index = 0; index < allEl.length; index++) {
        //     const element = allEl[index];
        //     element.classList.remove('bg-danger')
        // }
        // el.classList.add('bg-danger')

    }, [currentPage])

    return (
        <>



            <header className="navbar header back">


                <div className="display">

                    <div className="nav-div">
                        <div className="overflow-y-hidden h-[80px] mb-1">
                            <Link to='/'>
                                <img className="navbar-brand logo" alt="" src="/images/logos/white tedx.png"></img>
                            </Link>
                        </div>

                        <div className="menu-only justify-items-center mb-1" >
                            <Link to='/' className="text-decoration-none"><div className="navlink backg bg-transparent rounded  text-light px-3 py-1 mx-2 mb-1 Home">HOME</div></Link>
                            <Link to='/about' className="text-decoration-none"><div className="navlink bg-transparent backg rounded text-light px-3 py-1 mx-2 mb-1 about"> ABOUT</div></Link>

                            <Link to='/speakers' className="text-decoration-none"><div className="navlink bg-transparent backg rounded text-light px-3 py-1 mx-2 mb-1 speakers"> SPEAKERS</div></Link>
                            <Link to='/talks' className="text-decoration-none"><div className="navlink bg-transparent backg rounded text-light px-3 py-1 mx-2  mb-1 talks">TALKS</div></Link>
                            {/* <Link to='/gallery' className="text-decoration-none"><div className="navlink rounded-pill text-light px-3 py-2 mx-2 gallery"><FontAwesomeIcon icon={faImages} /> Gallery</div></Link> */}
                            {/* <Link to='/team' className="text-decoration-none"><div className="navlink bg-transparent backg rounded text-light px-3 py-1 ms-2 mb-1 team">TEAM</div></Link> */}
                            {/* <Link to='/sponsors' className="text-decoration-none" ><div className="navlink bg-transparent backg rounded text-light px-3 py-1 ms-2 mb -1 sponsors"> SPONSORS</div></Link> */}
                            <Link to='/contact' className="text-decoration-none" ><div className="navlink bg-transparent backg rounded text-light px-3 py-1 ms-2 mb -1 contact">CONTACT US</div></Link>
                            <Link to='https://forms.gle/4UWj9FkSNt2yKf3R8' className="text-decoration-none">
                                <div className="navlink  text-light px-3 py-2 mx-2 ticket">
                                    BOOK TICKETS
                                </div>
                            </Link>
                        </div>
                        <div className="menu-drawer">
                            <MenuDrawer />
                        </div>
                    </div>
                    <div>
                        {/* <Announcement /> */}
                    </div>
                </div>



            </header>

        </>
    )


}

export default Navbar