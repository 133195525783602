import React, { useEffect } from "react";
import "./HomePage.css";
import { speakers } from "../../data/speakers";
import { Link } from "react-router-dom";
import { talks } from "../../data/talks";
import TalksCard from "../../components/TalksCard/TalksCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import FormII from "../../components/Form/FormII";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Merch from "../../components/Merch/Merch";
import { Button } from "@mui/material";
import MultiLayerParallax from "./MultilayerParallex";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  // width: "400px",
  pt: 1,
  pb: 4,
  px: 3,
};
function Home() {
  // const S2022 = speakers.filter((s) => s.date.includes("2022"));
  // const S2018 = speakers.filter((s) => s.date.includes("2018"));
  // const S2017 = speakers.filter((s) => s.date.includes("2017"));

  const rand = Math.abs(Math.floor(Math.random() * 16) - 4);

  let tempTalks = [...talks].splice(rand, 3);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <section className="hero">

        <div className="d-flex align-items-center">

          <div className="text w-[100vw]">

            <MultiLayerParallax />


          </div>

        </div>
        <section data-aos="fade-up">
        </section>
      </section>


      {/* <section className="bulletin bg-light py-5" data-aos="fade-up">
                <div className="container text-dark">
                    <div className="h2 ps-2 mb-2" >Updates <FontAwesomeIcon icon={faAnglesRight} /> </div>
                    <br />
                    <AccordionWrapper>
                        {news.map((item, index) => (
                            <AccordionItem key={index} index={index} item={item} />
                        ))}
                    </AccordionWrapper>
                </div>
                <br />
            </section> */}

      <section className="tedx py-5" data-aos="fade-up">
        <div className="container text-light">
          <div className="row justify-content-between">
            <div className="col-12 col-md-8 p-3">
              <div className="h2 ps-2 mb-2">
                What is TED<sup className="text-danger">x</sup>{" "}
              </div>
              <p className="text-2 fw-bold">
                {" "}
                <span className="text-3">x</span> = Independently organized
                event
              </p>
              <p>
                TEDx was created in 2009 in the spirit of TED's mission, "Ideas
                worth spreading". It supports independent organizers who want to
                create TED-like event in their own community.
              </p>
              <p>
                In the spirit of ideas worth spreading, TEDx is a program of
                local, self-organized events that bring people together to share
                a TED-like experience. At a TEDx event, TEDTalks video and live
                speakers combine to spark deep discussion and connection in a
                small group. These local, self-organized events are branded
                TEDx, where x = independently organized TED event. The TED
                Conference provides general guidance for the TEDx program, but
                individual TEDx events are self-organized (subject to certain
                rules and regulations).
              </p>
            </div>
            <div className="col-12 col-md-4 p-3">
              <iframe
                className="w-100 "
                style={{
                  borderRadius: "16px",
                   border: "5px solid #9D28EB",
                  
                }}
                height={280}
                src="https://www.youtube.com/embed/d0NHOpeczUU"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="speakers py-5">
        <div className="container text-light">
          <div className="h2 ps-2 mb-2">Speakers </div>
          <br />
          <div className="text-secondary justify-content-center row">
            <div
              data-aos="zoom-in"
              data-aos-duration="700"
              className="text-center col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center"
            >
              <TextMobileStepper images={S2022} yr={2022} />
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="700"
              className="text-center col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center"
            >
              <TextMobileStepper images={S2018} yr={2018} />
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="700"
              className="text-center col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center"
            >
              <TextMobileStepper images={S2017} yr={2017} />
            </div>
          </div>
          <br />
          <br />
          <div className="h5">
            <Link to={"/speakers"} className="years">
              {" "}
              See More <FontAwesomeIcon size="sm" icon={faArrowCircleRight} />
            </Link>
          </div>
        </div>
      </section> */}


      
      <section className="highlights py-8">
        <div className="container text-light py-4">
          <div className="h2 ps-2 mb-2">Event Highlights </div>
          <br />
          <div
            className="row"
            style={{
              overflowX: "hidden",
            }}
          >
            {tempTalks.map((talk, i) => (
              <div
                key={i}
                className="col-12 col-md-4"
                
                data-aos="fade-left"
                data-aos-delay={i * 200 + 200}
              >
                <TalksCard talk={talk} />
              </div>
            ))}
          </div>
          <div className="h5">
            <Link to={"/talks"} className="years">
              {" "}
              See More <FontAwesomeIcon size="sm" icon={faArrowCircleRight} />
            </Link>
          </div>
        </div>
      </section>


      <section className="location py-5" data-aos="fade-up">
        <div className="container text-light">
          <div className="row my-3">
            <div className="h3 col-12 col-md-7 text-center">
              <div className="text-4 mb-2">VENUE</div>
              <div className="lead mb-3">
                In the campus of IIT Indore, Khandwa road, Simrol
              </div>
            </div>
            <div className="col-12 col-md-5 p-2">
              <iframe
                title="map"
                style={{
                  borderRadius: "16px",
                  border: "5px solid #9D28EB",
                  // boxShadow: "0 0 20px #C780FA",
                }}
                className="w-100 m-0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44173.68003234819!2d75.89840349008908!3d22.530456862910512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962efcccbce7145%3A0x784e8cb69818596b!2sIndian%20Institute%20Of%20Technology%E2%80%93Indore%20(IIT%E2%80%93Indore)!5e0!3m2!1sen!2sin!4v1646406923144!5m2!1sen!2sin"
                height={300}
                allowFullScreen
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="sm:w-[300px] md:w-[450px] w-[90%] grid grid-cols-1 gap-4"
        >
          <FormII></FormII>
          <div className="sm:flex sm:items-center sm:gap-4 w-100 flex justify-center items-center">
            <button
              onClick={handleClose}
              className="inline-block shrink-0 rounded-md border border-[#9a2be9] bg-[#9a2be9] px-12 py-3 text-3xl font-medium text-white transition hover:bg-transparent hover:text-[#9a2be9] focus:outline-none focus:ring active:text-blue-500"
            >
              Submit
            </button>
          </div>
        </Box>
      </Modal> */}
    </>
  );
}

export default Home;
