export const teams = [
    {
        "sno": 1,
        "name": "Sahil Singh Shekhawat ",
        "team": "Organizer",
        "linkedin": "http://linkedin.com/in/sahil-singh-shekhawat-157924227",
        "instagram": "https://instagram.com/a_moltres?igshid=YmMyMTA2M2Y=",
        "head": false,
        "email": "ce200004043@iiti.ac.in"
    },
    {
        "sno": 2,
        "name": "Saurabh Yadav",
        "team": "Publicity",
        "linkedin": "https://www.linkedin.com/in/saurabh-yadav-a588a0229",
        "instagram": "https://www.instagram.com/saurabh1201/",
        "head": false,
        "email": "phd2101191001@iiti.ac.in"
    },
    {
        "sno": 3,
        "name": "KUNAL CHANDEL ",
        "team": "Outreach",
        "linkedin": "https://www.linkedin.com/in/kunal-chandel-83626a24b",
        "instagram": "https://instagram.com/00__kunal?igshid=NTdlMDg3MTY=",
        "head": false,
        "email": "ce210004024@iiti.ac.in"
    },
    {
        "sno": 4,
        "name": "Manya Rajib Jain",
        "team": "Head of Marketing",
        "linkedin": "https://www.linkedin.com/in/manya-rajib-jain-7a972a22b",
        "instagram": "https://instagram.com/manyarajib?igshid=NTdlMDg3MTY=",
        "head": true,
        "email": "ce210004027@iiti.ac.in"
    },
    {
        "sno": 5,
        "name": "Princy Sondarva ",
        "team": "Publicity",
        "linkedin": "https://www.linkedin.com/in/princy-sondarva-85004b250",
        "instagram": "Don't have an account on Instagram ",
        "head": false,
        "email": "cse210001068@iiti.ac.in"
    },
    {
        "sno": 6,
        "name": "Rohit Dhanotia ",
        "team": "Web Dev.",
        "linkedin": "https://www.linkedin.com/in/rohit-dhanotia-419948218/",
        "instagram": "https://www.instagram.com/_rohit_dhanotia_/",
        "head": false,
        "email": "cse210001059@iiti.ac.in"
    },
    {
        "sno": 7,
        "name": "Diwakar Soni",
        "team": "Outreach Head",
        "linkedin": "https://www.linkedin.com/in/diwakar-soni-82b8701ba/",
        "instagram": "https://www.instagram.com/diwakar1009_/",
        "head": true,
        "email": "ee200002031@iiti.ac.in"
    },
    {
        "sno": 8,
        "name": "Bhom Singh ",
        "team": "Publicity",
        "linkedin": "https://www.linkedin.com/in/bhom-singh-3533861b7",
        "instagram": "https://www.instagram.com/bhom_singh____7773/?next=%2F",
        "head": false,
        "email": "ce200004010@iiti.ac.in"
    },
    {
        "sno": 9,
        "name": "Vyankatesh Chavan ",
        "team": "Design and Aesthetics",
        "linkedin": "https://www.linkedin.com/in/vyankatesh-chavan-bbb383230",
        "instagram": "vyankatesh_chavan_",
        "head": false,
        "email": "mems210005053@iiti.ac.in"
    },
    {
        "sno": 10,
        "name": "Shivmuni Sarup",
        "team": "Design and Aesthetics",
        "linkedin": "https://www.linkedin.com/in/shivmuni-sarup-89191519a",
        "instagram": "https://www.instagram.com/invites/contact/?i=197rm1hglg849&utm_content=f52rva3",
        "head": false,
        "email": "msc2203171023@iiti.ac.in"
    },
    {
        "sno": 11,
        "name": "KRRISH",
        "team": "Marketing and Sponsorship",
        "linkedin": "https://www.linkedin.com/mwlite/in/krrish-bb375a24b",
        "instagram": "https://www.instagram.com/_krri.xhhhh/",
        "head": false,
        "email": "ce220004027@iiti.ac.in"
    },
    {
        "sno": 12,
        "name": "Atharva dhore",
        "team": "Marketing and Sponsorship",
        "linkedin": "https://www.linkedin.com/in/atharva-dhore-b9502b23b",
        "instagram": "https://www.instagram.com/atharva_dhore7/",
        "head": false,
        "email": "me210003019@iiti.ac.in"
    },
    {
        "sno": 13,
        "name": "RAHUL CHAUHAN",
        "team": "Publicity",
        "linkedin": "https://www.linkedin.com/in/rahul-chauhan-93519391/",
        "instagram": "https://www.instagram.com/rahul54chauhan",
        "head": false,
        "email": "phd1901171006@iiti.ac.in"
    },
    {
        "sno": 14,
        "name": "Jaydeep Umarya ",
        "team": "Design and Aesthetics",
        "linkedin": "https://www.linkedin.com/in/jaydeep-umarya-779413202/",
        "instagram": "https://www.instagram.com/jaydeepumarya/",
        "head": false,
        "email": "me200003035@iiti.ac.in"
    },
    {
        "sno": 15,
        "name": "Prince Suman",
        "team": "Publicity",
        "linkedin": "Prince Suman ",
        "instagram": "princesuman1212",
        "head": false,
        "email": "ee220002064@iiti.ac.in"
    },
    {
        "sno": 16,
        "name": "Mansi Choudhary",
        "team": "Curator",
        "linkedin": "https://www.linkedin.com/in/mansi-choudhary-49b945231",
        "instagram": "https://instagram.com/_mansichoudhary17_?igshid=ZDdkNTZiNTM=",
        "head": false,
        "email": "ce210004025@iiti.ac.in"
    },
    {
        "sno": 17,
        "name": "Suhith Karralla ",
        "team": "Design and Aesthetics",
        "linkedin": "https://www.linkedin.com/mwlite/in/karralla-suhith-84140a243",
        "instagram": "https://www.instagram.com/just.suhith/",
        "head": false,
        "email": "ce210004020@iiti.ac.in"
    },
    {
        "sno": 18,
        "name": "SriVaishnav.K",
        "team": "Video and  Production",
        "linkedin": "SriVaishnav K",
        "instagram": "i_srivaishnav08",
        "head": false,
        "email": "ee210002048@iiti.ac.in"
    },
    {
        "sno": 19,
        "name": "Abhijeet Singh ",
        "team": "Outreach",
        "linkedin": "www.linkedin.com/in/abhijeet-singh-a66103171",
        "instagram": "https://www.instagram.com/oldschoolabhii/",
        "head": false,
        "email": "msc2103171025@iiti.ac.in"
    },
    {
        "sno": 20,
        "name": "Avaneesh Pandey",
        "team": "Web Dev.",
        "linkedin": "https://www.linkedin.com/in/avaneesh-pandey-750a7320a",
        "instagram": "https://www.instagram.com/_avaneesh_p/",
        "head": false,
        "email": "ee200002017@iiti.ac.in"
    },
    {
        "sno": 21,
        "name": "Amarnath K",
        "team": "Web Team Head",
        "linkedin": "https://www.linkedin.com/in/amarnath-k-232878210/",
        "instagram": "https://www.instagram.com/e13tron/",
        "head": true,
        "email": "me200003010@iiti.ac.in"
    },
    {
        "sno": 22,
        "name": "P. V. Sekhar",
        "team": "Web Dev.",
        "linkedin": "https://www.linkedin.com/in/p-v-sekhar-bb4612239/",
        "instagram": "https://www.instagram.com/p_v_sekhar/",
        "head": false,
        "email": "cse210001051@iiti.ac.in"
    },
    {
        "sno": 23,
        "name": "Maitreya Pathak",
        "team": "Curator",
        "linkedin": "https://www.linkedin.com/in/maitreyapathak/",
        "instagram": "https://www.instagram.com/maitreya2703/",
        "head": false,
        "email": "mems210005027@iiti.ac.in"
    },
    {
        "sno": 24,
        "name": "YAMINI SUNKAR ",
        "team": "Design and Aesthetics",
        "linkedin": "https://www.linkedin.com/in/yamini-sunkar-531026243",
        "instagram": "https://www.instagram.com/invites/contact/?i=rio0kqzyms9p&utm_content=1kd9o88",
        "head": false,
        "email": "ce210004052@iiti.ac.in"
    },
    {
        "sno": 25,
        "name": "Dhairya Mistry",
        "team": "Marketing and Sponsorship",
        "linkedin": "https://www.linkedin.com/in/djmistry02/",
        "instagram": "https://www.instagram.com/_dhairya.mistry_/",
        "head": false,
        "email": "me200003025@iiti.ac.in"
    },
    {
        "sno": 26,
        "name": "Pranjal Jaiswal",
        "team": "Team Head Video Production",
        "linkedin": "X",
        "instagram": "X",
        "head": true,
        "email": "mems210005033@iiti.ac.in"
    },
    {
        "sno": 27,
        "name": "Chirag Gour",
        "team": "Outreach",
        "linkedin": "https://www.linkedin.com/in/chirag-gour-a58941232",
        "instagram": "https://instagram.com/_chirag__17?igshid=YmMyMTA2M2Y=",
        "head": false,
        "email": "mems210005016@iiti.ac.in"
    },
    {
        "sno": 28,
        "name": "Monal Suresh Pawar",
        "team": "Outreach",
        "linkedin": "https://www.linkedin.com/in/monal-pawar-039808214",
        "instagram": "monal__p",
        "head": false,
        "email": "me200003047@iiti.ac.in"
    },
    {
        "sno": 29,
        "name": "Aniket Mandawkar",
        "team": "Publicity",
        "linkedin": "https://www.linkedin.com/in/aniket-mandawkar/",
        "instagram": "https://instagram.com/aniket_mandawkar?igshid=NTdlMDg3MTY=",
        "head": false,
        "email": "mems210005007@iiti.ac.in"
    },
    {
        "sno": 30,
        "name": "Himanshu khati ",
        "team": "Marketing and Sponsorship",
        "linkedin": "https://www.linkedin.com/in/himanshu-khati-b7061b231",
        "instagram": "https://www.instagram.com/invites/contact/?i=dtfrrsh5lkeh&utm_content=mxzhtjg",
        "head": false,
        "email": "ce210004017@iiti.ac.in"
    },
    {
        "sno": 31,
        "name": "Ayush",
        "team": "Content",
        "linkedin": "www.linkedin.com/in/ayush-247-p1",
        "instagram": "https://www.instagram.com/ayush_247_p1/",
        "head": false,
        "email": "ce200004005@iiti.ac.in"
    },
    {
        "sno": 32,
        "name": "Charmi",
        "team": "Marketing and Sponsorship",
        "linkedin": "https://www.linkedin.com/in/charmi-edula-19461b260",
        "instagram": "instagram.com/edulacharmi/",
        "head": false,
        "email": "ce220004017@iiti.ac.in"
    },
    {
        "sno": 33,
        "name": "Manisha Devi",
        "team": "Co-Organizer",
        "linkedin": "https://www.linkedin.com/in/manisha-sahu-5575b9205",
        "instagram": "https://www.instagram.com/mani_shail/",
        "head": false,
        "email": "me200003046@iiti.ac.in"
    },
    {
        "sno": 34,
        "name": "Ishita Pandey",
        "team": "Marketing and Sponsorship",
        "linkedin": "www.linkedin.com/in/ishita-pandey-138725260",
        "instagram": "https://www.instagram.com/ishitapandey224/",
        "head": false,
        "email": "ee220002037@iiti.ac.in"
    },
    {
        "sno": 35,
        "name": "Rohan Maji ",
        "team": "Video and  Production",
        "linkedin": "https://www.linkedin.com/in/rohan-maji-93053a249/",
        "instagram": "https://www.instagram.com/rohanmaji.03/",
        "head": false,
        "email": "ee220002070@iiti.ac.in"
    },
    {
        "sno": 36,
        "name": "Dhruv Dhirawani ",
        "team": "Outreach",
        "linkedin": "https://www.linkedin.com/in/dhruv-dhirawani-8b6b9225a",
        "instagram": "https://www.instagram.com/dhruv_dhirawani/",
        "head": false,
        "email": "me220003025@iiti.ac.in"
    },
    {
        "sno": 37,
        "name": "Piyush Yadav",
        "team": "Operation & Logistics Head",
        "linkedin": "https://www.linkedin.com/in/piyush2305/",
        "instagram": "https://www.instagram.com/raopiyush23/",
        "head": true,
        "email": "ce200004037@iiti.ac.in"
    },
    {
        "sno": 38,
        "name": "Divya Singh Maurya ",
        "team": "Marketing and Sponsorship",
        "linkedin": "https://www.linkedin.com/in/divya-singh-maurya-b04747220",
        "instagram": "https://www.instagram.com/diva_sm_23/",
        "head": false,
        "email": "mems220005020@iiti.ac.in"
    },
    {
        "sno": 39,
        "name": "Rajveer Jadhav",
        "team": "Hospitality Head",
        "linkedin": "https://www.linkedin.com/in/rajveer-jadhav-a7651323b",
        "instagram": "https://instagram.com/rajveerjadhav2912?igshid=NmQ2ZmYxZjA=",
        "head": true,
        "email": "mems210005037@iiti.ac.in"
    }
]
