import { Box, Modal} from '@mui/material'
import React from 'react'
import './SpeakersCard.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '425px',
    minWidth: '320px',
    height: '95vh',
    bgcolor: '#9D28EB',
    height: '560px',
    border: '2px solid #000',
    p: 3,
    borderRadius: '16px',
    zIndex: '3000'
};

export default function SpeakersCard({ speaker }) {

    const { name, post, description, imgPath } = speaker
    const [open, setOpen] = React.useState(false);
    const [modalobj, setObj] = React.useState({});
    const handleOpen = (obj) => {
        setObj(obj);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
        setObj({});
    };

    return (
        <>
            <div elevation={3} sx={{ width: '280px', height: 'auto', overflow: 'visible', bgcolor: '#9D28EB', borderRadius: '10px' }}  >
                <div className='text-center' >
                    <img
                        onClick={() => handleOpen({ name, post, description })}
                        role={'button'}
                        height="210px"
                        width="210px"
                        src={imgPath}
                        alt={name}
                        style={{ borderRadius: '100%', width: "210px", height: "210px", position: 'relative', objectFit: 'cover',  border: '5px solid #9D28EB', padding: 2 }}

                    />
                    <div className='mt-2' style={{ padding: 10, borderRadius: '10px', width: '100%' }}>
                        <div className='speaker-name'>
                            {name.toUpperCase()}
                        </div>
                        <div className='speaker-post'>
                            {post}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                   
                    {/* <img alt={name} src={imgPath} height={265}></img>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2 }}>
                        {modalobj.name}
                    </Typography>
                    <Typography id="modal-modal-description" variant='p' component="h6" color={'#e62b1e'} sx={{ mb: 2 }} >
                        {modalobj.post}
                    </Typography>
                    <Typography id="modal-modal-description" variant='body1' sx={{ mt: 2 }}>
                    {modalobj.description}
                    </Typography> */}

                    <main>
                        <div class="top-card">
                           <img alt={name} src={imgPath} height={165}></img>
                        </div>

                        <section className="middle-card">
                            <div className='center'>
                                <h3 className='name'>{modalobj.name}</h3>
                                <h3 className='post'>{modalobj.post}</h3>
                            </div>
                            <p>{modalobj.description}</p>
                        </section>

                        <footer>
                            <a href="#" className="social-icon facebook"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" className="social-icon twitter"><i class="fab fa-twitter"></i></a>
                            <a href="#" className="social-icon linkedin"><i class="fab fa-linkedin"></i></a>
                        </footer>
                    </main>


                </Box>
            </Modal>
        </>
    )
}



